.login_page_form{
  padding: 40px 40px !important;
  border-radius: 10px;
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
  margin: 0 auto;
  background-color: #fff;
}

.page_bg_color{
  background:#e8f0fe85 !important;
}